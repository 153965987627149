
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component({
  name: 'InvoiceDialog'
})
export default class extends Vue {
  @Prop({ required: true }) private pdfUrl!: string
  @Prop({ required: true }) private visible!: boolean
  @Prop({ required: true }) private title!: string

  public src = ''

  created() {
    this.fetchPdf()
  }

  private fetchPdf() {
    fetch(this.pdfUrl)
      .then(res => res.blob())
      .then(blob => { this.src = URL.createObjectURL(blob) })
  }

  mounted() {
    window.history.pushState(null, '', window.location.href)
    window.onpopstate = () => {
      window.history.pushState(null, '', window.location.href)
      this.$emit('close')
    }
  }
}

