
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { Order, Invoice } from '@/models'
import { useUserStore } from '@/stores/user'

@Component({
  name: 'InvoiceAsideDetails',
  components: {
    UploadInvoice: () => import('./upload-invoice/index.vue'),
    EditValuePopover: () => import('./EditValuePopover.vue'),
    InvoiceDialog: () => import('./InvoiceDialog.vue'),
    ProofOfPayment: () => import('./proof-of-payment/index.vue'),
    InvoiceOrderCard: () => import('./InvoiceOrderCard.vue'),
    CommentInvoice: () => import('./CommentInvoice.vue')
  }
})
export default class extends Vue {
  @Prop({ required: true, default: null }) invoiceSelected!: Invoice | null

  userStore: any = useUserStore()

  private dialogUploadInvoice = false
  private dialogInvoiceToChange: Invoice | null = null
  private scrollPosition = 90

  private pdfDialog = false
  private pdfUrl = ''

  orders: Order[] = []
  ordersLoading = false
  lastOrdersPageReached = false
  paginationOrders = {
    page: 1,
    limit: 5
  }

  filters: any = {
    orderNumber: null
  }

  private paymentStatusTagTypes = {
    paid: 'success',
    unpaid: 'warning',
    late: 'danger'
  }

  private paymentStatusResultTypes = {
    paid: 'success',
    unpaid: 'warning',
    late: 'error'
  }

  private paymentStatusResultTitles = {
    paid: this.$t('paymentStatus.paid'),
    unpaid: this.$t('paymentStatus.unpaid'),
    late: this.$t('paymentStatus.late')
  }

  private paymentStatusResultSubtitles = {
    paid: this.$t('invoices.asidePanel.paymentStatusResultSubtitles.paid'),
    unpaid: this.$t('invoices.asidePanel.paymentStatusResultSubtitles.unpaid'),
    late: this.$t('invoices.asidePanel.paymentStatusResultSubtitles.late')
  }

  get scrollHeight() {
    return this.scrollPosition
  }

  get isWholesalerOrSeller() {
    return this.userStore.roles.includes('wholesaler') || this.userStore.roles.includes('seller')
  }

  get isWholesalerOrBuyer() {
    return this.userStore.roles.includes('wholesaler') || this.userStore.roles.includes('buyer')
  }

  async getOrdersByInvoiceId(): Promise<void> {
    this.ordersLoading = true

    const { data } = await Order
      .where({
        invoiceId: this.invoiceSelected!.id,
        number: { prefix: this.filters.orderNumber }
      })
      .page(this.paginationOrders.page)
      .per(this.paginationOrders.limit)
      .all()

    if (data.length === 0) this.lastOrdersPageReached = true
    this.orders.push(...data)
    this.ordersLoading = false
  }

  @Watch('invoiceSelected')
  setOrdersList(value: any): void {
    if (value) this.resetOrderList()
  }

  handleSearchOrder() {
    this.resetOrderList()
  }

  clearInputOrderNumber() {
    this.filters.orderNumber = null
    this.resetOrderList()
  }

  private resetOrderList(): void {
    this.paginationOrders.page = 1
    this.orders = []
    this.lastOrdersPageReached = false
    this.getOrdersByInvoiceId()
  }

  private openUploadInvoiceDialog(invoice: Invoice) {
    this.dialogInvoiceToChange = invoice
    this.dialogUploadInvoice = true
  }

  mounted() {
    window.addEventListener('scroll', this.updateScroll)
  }

  destroy() {
    window.removeEventListener('scroll', this.updateScroll)
  }

  private showPdf(url: string) {
    window.open(url)
  }

  private showProofOfPayment(proofUrl: string) {
    this.pdfUrl = proofUrl
    this.pdfDialog = true
  }

  private totalBankTransfers(bankTransfers: any) {
    let total = 0
    bankTransfers.map((e: any) => { total += e.amount })
    return total
  }

  private updateScroll() {
    this.scrollPosition = window.scrollY === 0 ? 90 : window.scrollY >= 70 ? 15 : 60
  }

  private updateInvoice(invoice: Invoice) {
    let invoiceSelected = invoice

    if (invoice.mode === 'commission') {
      invoiceSelected = this.invoiceSelected!.dup()
      invoiceSelected.commissionInvoice = invoice
    }

    this.$emit('updateInvoice', invoiceSelected)
  }
}
