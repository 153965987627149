
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { Invoice } from '@/models'
import { Form } from "element-ui";

@Component({
  name: 'EditValuePopover'
})
export default class extends Vue {
  @Prop({ required: true }) invoiceSelected!: Invoice
  @Prop({ required: true }) valueToChange!: string
  @Prop({ required: false, default: 'Title' }) title!: string
  @Prop({ required: false, default: 'Placeholder' }) placeholder!: string
  @Prop({ required: false, default: 'text' }) btnType!: string
  @Prop({ required: false, default: 'Save' }) btnText!: string

  private tempInvoice: Invoice = new Invoice()
  private invoiceLoading = false
  private visible = false

  private rules = {
    [this.valueToChange]: [
      {
        required: this.valueToChange === 'number',
        message: this.valueToChange === 'number' ? this.$t('invoices.asidePanel.popoverValue.error.number') : this.$t('invoices.asidePanel.popoverValue.error.customNumber'),
        trigger: 'blur'
      }
    ],
  }

  @Watch('visible')
  setTempInvoice(visible: boolean): void {
    if (visible) {
      ;(this.$refs[`form_invoice_${this.invoiceSelected.id}`] as Form).clearValidate()
      this.tempInvoice = this.invoiceSelected.dup()
    }
  }

  async update(): Promise<void> {
    (this.$refs[`form_invoice_${this.invoiceSelected.id}`] as Form).validate(async(valid) => {
      if (valid) {
        this.invoiceLoading = true

        try {
          await this.tempInvoice.save()
          this.$emit('updateInvoice', this.tempInvoice.dup())
        } catch (e) {
          this.$notify({
            title: 'Error',
            message: '',
            type: 'error',
            duration: 2000
          });
        }
        this.invoiceLoading = false
        ;(this.$refs[`popover_ref_${this.invoiceSelected.id}`] as any).doClose()
      }
    })
  }
}
