
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { Buyer, Seller } from "@/models";
import { useUserStore } from '@/stores/user'

@Component({
  name: 'FiltersDrawer'
})
export default class extends Vue {
  @Prop({ required: true }) wholesalerInvoiceType!: string

  userStore: any = useUserStore()

  private showFilters = false

  private sellers: Seller[] = []
  private sellersLoading = false

  private buyers: Buyer[] = []
  private buyersLoading = false

  private filters: any = {
    number: null,
    orderNumber: null,
    sellerId: null,
    buyerId: null,
    paymentMode: [],
    invoicePer: [],
    paymentStatus: null,
    dateRange: null
  }

  private paymentStatus = [
    {
      label: this.$t('paymentStatus.paid'),
      value: 'paid'
    },
    {
      label: this.$t('paymentStatus.late'),
      value: 'late'
    },
    {
      label: this.$t('paymentStatus.unpaid'),
      value: 'unpaid'
    }
  ]

  private invoicePer = [
    {
      label: this.$t('invoices.header.filters.invoicePerOrder'),
      value: 'invoice_per_order'
    },
    {
      label: this.$t('invoices.header.filters.invoicePerMonth'),
      value: 'invoice_per_month'
    }
  ]

  get isInvoiceTypeSeller() {
    return this.wholesalerInvoiceType === 'seller'
  }

  get isWholesaler() {
    return this.userStore.roles.includes('wholesaler')
  }

  get filtersActive() {
    return Object.keys(this.filters).filter(i => {
      if (this.filters[i] !== null) {
        if ((typeof this.filters[i] === 'object' && this.filters[i].length > 0) || typeof this.filters[i] !== 'object') {
          if (i !== 'page') {
            return i
          }
        }
      }
    }).length
  }

  @Watch('wholesalerInvoiceType', { immediate: true })
  private fetchSellersOrBuyers(invoiceType: string) {
    if (invoiceType === 'seller') this.getSellers()
    else this.getBuyers()
  }

  private async getSellers() {
    this.sellersLoading = true
    this.sellers = []

    const { data } = await Seller
      .page(1)
      .per(999)
      .order('name')
      .all()

    this.sellers = { ...data }
    this.sellersLoading = false
  }

  private async getBuyers() {
    this.buyersLoading = true
    this.buyers = []

    const { data } = await Buyer
      .page(1)
      .per(999)
      .order('name')
      .all()

    this.buyers = { ...data }
    this.buyersLoading = false
  }

  private handleSellerSelect(sellerId: string) {
    this.filters.sellerId = sellerId
    this.$emit('applyFilters', this.filters)
  }

  private handleBuyerSelect(buyerId: string) {
    this.filters.buyerId = buyerId
    this.$emit('applyFilters', this.filters)
  }

  refresh(): void {
    this.showFilters = false
    this.$emit('applyFilters', this.filters)
  }

  resetFilters(): void  {
    this.filters = {
      number: null,
      sellerId: null,
      buyerId: null,
      paymentMode: [],
      invoicePer: [],
      paymentStatus: null,
      orderNumber: null,
      dateRange: null
    }
    this.$emit('applyFilters', this.filters)
  }
}
