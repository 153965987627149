import { render, staticRenderFns } from "./InvoiceAsideDetails.vue?vue&type=template&id=751d0b8d&"
import script from "./InvoiceAsideDetails.vue?vue&type=script&lang=ts&"
export * from "./InvoiceAsideDetails.vue?vue&type=script&lang=ts&"
import style0 from "./InvoiceAsideDetails.vue?vue&type=style&index=0&id=751d0b8d&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports