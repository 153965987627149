
import { Component, Prop, Vue } from 'vue-property-decorator'
import { Invoice, Order } from '@/models'
import { useUserStore } from "@/stores/user";

@Component({
  name: 'InvoiceOrderCard'
})
export default class extends Vue {
  @Prop({ required: true }) order!: Order
  @Prop({ required: true }) invoice!: Invoice

  userStore: any = useUserStore()

  private queryValue: Invoice = new Invoice()
  private queryOptions: Invoice[] = []
  private queryLoading = false

  private saveLoading = false

  get isWholesalerBGO() {
    return this.userStore.roles.includes('wholesaler') && this.userStore.model.manageable.name === 'Browse&Go'
  }

  queryInvoice(query: string) {
    if (query) {
      this.queryLoading = true
      setTimeout(async() => {
        const { data } = await Invoice
          .includes(['seller'])
          .where({
            number: { prefix: query },
            sellerId: this.invoice.seller.id
          })
          .page(1)
          .per(5)
          .order({ createdAt: 'desc' })
          .all()

        this.queryOptions = { ...data }
        this.queryLoading = false
      }, 200)
    } else {
      this.queryOptions = []
    }
  }

  async switchInvoice() {
    this.saveLoading = true

    try {
      await this.order.switchInvoice(this.queryValue.id!)

      this.$notify({
        title: 'Succès',
        message: `La commande est rataché a la facture : ${this.queryValue.number} - ${this.queryValue.seller.name}`,
        type: 'success',
        duration: 2000
      })
      ;(this.$refs['popoverInvoice'] as any).doClose()
    } catch (error) {
      this.$notify({
        title: 'Erreur',
        message: `Impossible de ratacher la commande a la facture : ${this.queryValue.number} - ${this.queryValue.seller.name}`,
        type: 'error',
        duration: 2000
      })
    }

    this.saveLoading = false
  }
}
