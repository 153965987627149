
import { Component, Vue, Watch } from 'vue-property-decorator'
import { Route } from 'vue-router'
import { Dictionary } from 'vue-router/types/router'

import { useUserStore } from '@/stores/user'

@Component({
  name: 'Login'
})
export default class extends Vue {
  userStore: any = useUserStore()

  private redirect?: string
  private otherQuery: Dictionary<string> = {}
  private windowWidth = window.innerWidth

  private carouselOptions = [
    { title: 'Une marketplace eBons unique', label: 'Que vous soyez acheteur ou vendeur, notre platforme a été pensée pour optimiser et fluidifier la distribution des eBons.' },
    { title: 'Achetez vos eBons', label: "Un catalogue avec des dizaines d'enseignes et des remises toujours plus intéressantes." },
    { title: 'Vendez vos eBons', label: 'Consultez les commandes entrantes et gardez un oeil sur vos chiffres de ventes.' },
    { title: 'Livrez vos eBons', label: "Grâce à notre interface intuitive, livrer vos eBons n'aura jamais été aussi simple !" }
  ]

  created(): void {
    window.addEventListener('resize', () => {
      this.windowWidth = window.innerWidth
    })
  }

  @Watch('$route', { immediate: true })
  private onRouteChange(route: Route) {
    const query = route.query as Dictionary<string>
    if (query) {
      this.redirect = query.redirect
      this.otherQuery = this.getOtherQuery(query)
    }
  }

  async redirectToSignIn() {
    this.userStore.signinRedirect()
  }

  private getOtherQuery(query: Dictionary<string>) {
    return Object.keys(query).reduce((acc, cur) => {
      if (cur !== 'redirect') {
        acc[cur] = query[cur]
      }
      return acc
    }, {} as Dictionary<string>)
  }
}
