
import { Component, Prop, Vue } from 'vue-property-decorator'
import { BankTransfer } from '@/models'

@Component({
  name: 'ProofOfPayment'
})
export default class extends Vue {
  @Prop({ required: true, default: null }) private bankTransfer!: BankTransfer

  get proofIsPDF() {
    return this.fileExtension(this.bankTransfer.proofOfPayment) === 'pdf'
  }

  private showProofOfPayment(proofUrl: string) {
    if (this.proofIsPDF) this.$emit('open-proof', proofUrl)
  }

  private fileExtension(filename: string) {
    return typeof filename !== 'undefined' ? filename.substring(filename.lastIndexOf('.') + 1, filename.length).toLowerCase() : false
  }
}
