
import { Component, Prop, Vue } from 'vue-property-decorator'
import { Invoice } from '@/models'
import { Form as ElForm } from 'element-ui/types/element-ui'

@Component({
  name: 'UploadInvoice',
  components: {
    DirectUpload: () => import('@/components/DirectUpload/index.vue')
  }
})
export default class extends Vue {
  @Prop({ required: true, default: null }) private invoice!: Invoice
  @Prop({ required: true }) private visible!: boolean

  private tempInvoice: Invoice = new Invoice()
  loading = false
  rules: any = {
    number: [
      { required: true, message: this.$t('invoices.uploadInvoiceDialog.numberIsRequired'), trigger: 'blur' }
    ],
    codesFile: [
      { required: true, message: this.$t('invoices.uploadInvoiceDialog.fileIsRequired'), trigger: 'blur' }
    ]
  }

  created(): void {
    this.tempInvoice = this.invoice.dup()
  }

  async submitForm(): Promise<void> {
    (this.$refs.deliveryForm as ElForm).validate(async(valid) => {
      if (valid) {
        this.loading = true

        try {
          await this.tempInvoice.save()

          this.$emit('updateInvoice', this.tempInvoice.dup())

          this.$notify({
            title: this.$t('invoices.uploadInvoiceDialog.notification.success.title') as string,
            message: this.$t('invoices.uploadInvoiceDialog.notification.success.message') as string,
            type: 'success',
            duration: 2000
          })
          this.$emit('close')
        } catch (err) {
          this.$notify({
            title: this.$t('invoices.uploadInvoiceDialog.notification.error.title') as string,
            message: this.$t('invoices.uploadInvoiceDialog.notification.error.message') as string,
            type: 'error',
            duration: 2000
          })
          this.loading = false
        }
      }
    })
  }

  private uploadCallback(file: any) {
    this.tempInvoice.file = file.blob.signed_id
  }
}
