
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { Invoice } from '@/models'

@Component({
  name: 'CommentInvoice'
})
export default class extends Vue {
  @Prop({ required: true }) invoiceSelected!: Invoice

  tempInvoice: Invoice = new Invoice()
  edit = false
  editLoading = false

  @Watch('invoiceSelected', { immediate: true })
  setTempInvoice() {
    if (this.invoiceSelected) {
      this.edit = false
      this.tempInvoice = this.invoiceSelected.dup()
    }
  }

  resizeTextarea() {
    this.$nextTick(() => {
      const selector = `#comment_${this.tempInvoice!.id}`
      const textarea = document.querySelector(selector)
      if (textarea) {
        textarea.style.height = textarea.scrollHeight + 'px'
      }
    })

    this.edit = false
  }

  async editComment(): void {
    if (this.edit) {
      await this.update()
      this.edit = false
    } else {
      this.edit = true
      this.$nextTick(() => {
        this.$refs[`comment_${this.tempInvoice.id}`]!.focus()
      })
    }
  }

  async update(): Promise<void> {
    this.editLoading = true

    try {
      await this.tempInvoice.save()

      this.$emit('updateInvoice', this.tempInvoice.dup())
      this.$notify({
        title: this.$t('invoices.asidePanel.commentComponent.notification.success.title') as string,
        message: this.$t('invoices.asidePanel.commentComponent.notification.success.subtitle') as string,
        type: 'success',
        duration: 2000
      })
    } catch (e) {
      this.$notify({
        title: this.$t('invoices.asidePanel.commentComponent.notification.error.title') as string,
        message: this.$t('invoices.asidePanel.commentComponent.notification.error.subtitle') as string,
        type: 'error',
        duration: 2000
      })
    }
    this.editLoading = false
  }
}
