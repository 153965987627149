
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { Invoice } from '@/models'
import { useUserStore } from '@/stores/user'

@Component({
  name: 'InvoiceTable',
})
export default class extends Vue {
  @Prop({ required: true }) listQuery!: any
  @Prop({ required: true }) wholesalerInvoiceType!: string

  userStore: any = useUserStore()

  private invoices: Invoice[] = []
  private invoicesLoading = false
  private lastInvoicesPageReached = false

  private invoiceSelected: Invoice | null = null

  private pagination = {
    page: 1,
    limit: 10
  }

  private paymentStatusTagClass = {
    paid: 'invoice-paid',
    unpaid: 'invoice-unpaid',
    late: 'invoice-late'
  }

  get isWholesaler() {
    return this.userStore.roles.includes('wholesaler')
  }

  get isInvoiceTypeSeller() {
    return this.wholesalerInvoiceType === 'seller'
  }

  created() {
    this.getInvoices()
  }

  private async getInvoices() {
    this.invoicesLoading = true

    const issuedAtGte = this.listQuery.dateRange ? this.$moment(String(this.listQuery.dateRange![0])).startOf('day').format('YYYY-MM-DD HH:mm:ss') : ''
    const issuedAtLte = this.listQuery.dateRange ? this.$moment(String(this.listQuery.dateRange![1])).endOf('day').format('YYYY-MM-DD HH:mm:ss') : ''

    const includes = this.isWholesaler ? this.isInvoiceTypeSeller ? ['commissionInvoice', 'seller'] : ['commissionInvoice', 'buyer'] : ['commissionInvoice']

    const { data } = await Invoice
      .includes(includes)
      .where({ number: { prefix: this.listQuery.number } })
      .where({ paymentMode: this.listQuery.paymentMode })
      .where({ invoicePer: this.listQuery.invoicePer })
      .where({ paymentStatus: this.listQuery.paymentStatus })
      .where({ issuedAt: { gte: issuedAtGte, lte: issuedAtLte } })
      .where({ orderNumber: this.listQuery.orderNumber })
      .where({ sellerId: this.listQuery.sellerId })
      .where({ buyerId: this.listQuery.buyerId })
      .where({ belongs_to_buyers: this.isWholesaler ? !this.isInvoiceTypeSeller : null })
      .where({ mode: 'standard' })
      .order({ createdAt: 'desc' })
      .page(this.pagination.page)
      .per(this.pagination.limit)
      .all()

    if (data.length === 0) this.lastInvoicesPageReached = true
    this.invoices.push(...data)
    this.invoicesLoading = false
  }

  selectInvoice(invoice: Invoice | null) {
    this.invoiceSelected = invoice
    this.$emit('invoiceSelected', invoice)
  }

  updateSelectedInvoice(invoice: Invoice) {
    this.selectInvoice(invoice)
    const index = this.invoices.findIndex((i) => i.id === invoice.id)
    if (index >= 0) this.invoices.splice(index, 1, invoice)
  }

  @Watch('listQuery')
  refresh() {
    this.lastInvoicesPageReached = false
    this.pagination.page = 1
    this.invoices = []
    this.getInvoices()
  }
}
